<template>
  <div class="main-elements-wraper">
    <!-- search -->
    <div class="search-input-wraper">
      <img alt="img" class="search-img" src="@/assets/img/search.svg" />
      <input
        class="search-input"
        type="text"
        ref="searchInput"
        :placeholder="searchingCategory"
        v-model.trim="itemsInput"
      />
      <div
        v-show="itemsInput || searchingCategory"
        @click="clearSearchInput"
        class="clear-input"
      >
        &times;
      </div>
    </div>
    <div
      class="content-container"
      :style="containerStyles"
      :class="{ 'modal-categories': isUploadModal }"
    >
      <preloader class="preloader" :visible="visiblePreloader" />
      <div
        v-if="!itemsInput && !searchingCategory"
        class="categories"
        :class="{ 'modal-categories': isUploadModal }"
        :style="containerStyles"
      >
        <div
          class="category-container"
          v-for="(c, idx) of itemsByCategories"
          :key="-idx"
        >
          <div class="category-info">
            <div class="category-title">
              {{ c.category.name }}:
              <span style="color: #777">{{ c.count }}</span>
            </div>
            <div class="category-show-all" @click="onShowAll(c.category)">
              show all
            </div>
          </div>
          <div
            class="category-content"
            @scroll="onScrollCategory(c.category, $event)"
          >
            <div
              class="prev-ctg category-controllers"
              @click="c.scrollPos = ctgPrev(c.scrollPos)"
              v-show="c.scrollPos"
            >
              <img
                alt="img"
                class="category-controller-img"
                src="@/assets/img/prev.svg"
              />
            </div>
            <div
              class="galery-items"
              :style="{ transform: `translateX${'(' + c.scrollPos + 'px)'}` }"
            >
              <div class="gallery-item" v-for="e of c.items" :key="e.id">
                <div class="hover-wraper-content">
                  <img
                    alt="img"
                    class="info-menu"
                    @click.stop="
                      $store.dispatch('workSpace/showPopup', {
                        type: 'infoPopup',
                        data: { ...e.author, itemType: 'rich_image' }
                      })
                    "
                    src="@/assets/img/info.svg"
                  />
                  <img
                    alt="img"
                    @dragstart="handleDragStart"
                    @dragend="handleDragEnd"
                    v-drag-item="e"
                    v-lazy="e.img || e.content"
                    class="category-img"
                    v-double-click="{
                      cb: addToCanvas,
                      args: [e]
                    }"
                  />
                </div>

                <div class="category-item-title">
                  {{ e.name }}
                </div>
              </div>
            </div>
            <div
              class="next-ctg category-controllers"
              @click="
                c.scrollPos = ctgNext(c.scrollPos, c.items.length, c.category)
              "
            >
              <img
                alt="img"
                class="category-controller-img"
                src="@/assets/img/next.svg"
              />
            </div>
          </div>
        </div>
        <div :class="{ loader: showLoader }">
          <div
            v-infinity-scroll="{
              cb: renderItemsCategories
            }"
            id="scroll-trigger"
          ></div>
          <preloader class="preloader-inf" :visible="showLoader" />
        </div>
      </div>
      <div
        v-else-if="items[0] && (itemsInput || searchingCategory)"
        class="els-wraper"
        :class="{ 'modal-categories': isUploadModal }"
        :style="containerStyles"
      >
        <div class="els-img-wraper">
          <div class="elements" v-for="el of items" :key="el.id">
            <div class="els-container hover-wraper-content">
              <img
                alt="img"
                class="info-menu"
                @click.stop="
                  $store.dispatch('workSpace/showPopup', {
                    type: 'infoPopup',
                    data: { ...el.author, itemType: 'rich_image' }
                  })
                "
                src="@/assets/img/info.svg"
              />
              <img
                alt="img"
                @dragstart="handleDragStart"
                @dragend="handleDragEnd"
                v-drag-item="el"
                v-lazy="el.img || el.content"
                class="el-img"
                v-double-click="{
                  cb: addToCanvas,
                  args: [el]
                }"
              />
            </div>
            <span class="feed-item-title">{{ el.name }}</span>
          </div>
        </div>
      </div>
      <div
        class="feed-not-found"
        v-else-if="!items[0] && !visiblePreloader && itemsInput"
      >
        rich-mage {{ itemsInput }} not found
      </div>
    </div>
  </div>
</template>

<script>
import preloader from "@/components/preloaderMenu";
import AddToCanvasItem from "@/util/canvas/addToCanvas";
import dragAndDropMixin from "@/mixins/dragAndDropContent";
import contentTabsMixin from "@/mixins/contentTabsMixin";

export default {
  name: "PilRichImagesTab",
  mixins: [dragAndDropMixin, contentTabsMixin],
  props: {
    isUploadModal: {
      default: false
    }
  },
  components: {
    preloader
  },
  data() {
    return {
      tab: "richImages"
    };
  },
  methods: {
    addToCanvas(item) {
      if (this.isUploadModal) {
        this.$emit("edit-image", item.content);
        return;
      }
      AddToCanvasItem.richImage(item, false, "click");
    }
  }
};
</script>

<style scoped>
.els-wraper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-top: 0;
  margin: 0 5px;
  overflow: auto;
  height: calc(100vh - 245px);
  box-sizing: border-box;
}
.els-img-wraper {
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  width: 100%;
}
.elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 0 5px;
  width: calc(25% - 10px);
}
.els-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bcbccb;
  cursor: pointer;
  margin-bottom: 3px;
  width: 100%;
  width: 100%;
  border-radius: 7px;
}

.el-img {
  height: 93px;
  width: 100%;
  max-height: 65px;
  max-width: 65px;
  object-fit: contain;
}
.category-img {
  object-fit: contain;
  height: 65px;
  width: 65px;
}
.els-wraper,
.categories {
  position: relative;
  overflow-x: hidden;
}
.content-container {
  position: relative;
}
.preloader {
  z-index: 7;
}
.loader {
  height: 5px;
  position: relative;
}
.preloader-inf {
  position: absolute;
  height: 100px;
}
.els-container .info-menu {
  right: 0;
  bottom: 0;
}
.modal-categories {
  height: calc(100vh - 300px);
  max-height: 480px;
}
.modal-categories .info-menu,
.modal-categories .category-controllers {
  display: none;
}
.loader {
  height: 50px;
  position: relative;
}
</style>
